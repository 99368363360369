import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export type CanDeactivateReturn = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface DeactivableComponent {
    canDeactivate: () => CanDeactivateReturn;
}

export const canDeactivateGuard: CanDeactivateFn<DeactivableComponent> = (component: DeactivableComponent) => {
    return component.canDeactivate ? component.canDeactivate() : true;
};
